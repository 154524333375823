import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export interface typeUser {
  user_id: number;
  name: string;
  phone: string;
  email: string;
  profile_picture?: string;
  currency?: string;
}

export interface InterfaceUserStore {
  user: typeUser;
  isLogged: boolean;
  userWishlist: number[];
  setUserWishlist: (wishlist: number[]) => void;
  setUser: (user: typeUser) => void;
  resetUser: () => void;
  setIsLogged: (isLogged: boolean) => void;
}

const useUserStore = create<InterfaceUserStore>((set) => ({
  user: {
    user_id: 0,
    name: "",
    phone: "",
    email: "",
    profile_picture: "",
    currency: "   ",
  },
  isLogged: false,
  userWishlist: [],
  setUserWishlist: (wishlist) => set(() => ({ userWishlist: wishlist })),
  setIsLogged: (isLogged: boolean) => set(() => ({ isLogged })),
  setUser: (user) => set(() => ({ user })),
  resetUser: () =>
    set(() => ({
      user: {
        user_id: 0,
        name: "",
        phone: "",
        email: "",
        profile_picture: "",
      },
    })),
}));

mountStoreDevtool("UserStore", useUserStore);

export default useUserStore;
